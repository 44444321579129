<template>
<div class="done">
<lottie-animation path="animations/waiting.json"
                  :width="250"
                  :height="250"
/>
<h4 class=" xlarge bolder"> احراز هویت شما تکمیل شد و تا ساعاتی دیگر بررسی خواهد شد. نتیجه احراز هویت خود را می توانید در پنل اعلانات مشاهده فرمایید.</h4>

  <span class="regular warn">
    <img src="../../../assets/Icons/warning.png" alt="">
    <span>در صورت وجود مشکل در یکی از مراحل احراز هویت یا تمایل به ویرایش اطلاعات شخصی، می بایست فرایند احراز هویت را مجدد انجام بدهید.</span>
  </span>

</div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
  name: "Done",
  components: {
    LottieAnimation
  },
  data(){
    return{

    }
  }
}
</script>

<style lang="scss" scoped>

.warn{
  display: flex;
  gap: 4px;
  align-items: center;
}


.done{
  display: flex;
  flex-direction: column;
  align-items: center;


}
.bolder{
  font-weight: 1000;
}
.waiting{
  display: flex;
  gap: 10px;
span{
  height: 15px;
  width: 15px;
  background-color: var(--secondary);
  border-radius: 50%;
  display: inline-block;
  animation: 3s scale infinite ;
}

  span:last-child{
    animation: 3s scale infinite ;
    animation-delay: .25s !important;
  }

  span:first-child{
    animation: 3s scale infinite ;
    animation-delay: .5s !important;
  }





  @keyframes scale {
0%{
  transform: translateY(10px);
}

    50%{
      transform: translateY(-10px);
    }

    75%{
      transform: translateY(-10px);
    }

    100%{
      transform: translateY(10px);
    }


  }
}
</style>


<!--[component:lottie:https://assets9.lottiefiles.com/packages/lf20_Ch2iSK.json]-->