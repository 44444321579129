<template>
  <div class="otp">
    <p class="regular">اﯾﻦ ﺟﺎﻧﺐ زهراگودرزی ﺑﺎ ﺷﻤﺎره ﻣﻠﯽ 12389123465 در ﺗﺎرﯾﺦ 01/01/11 ﻣﺪارک ﺧﻮد را ﺟﻬﺖ اﺣﺮاز ﻫﻮﯾﺖ در ﺳﺎﯾﺖ ویستا ارﺳﺎل و
      اﻋﻠﺎم می کنم ﻣﺪارک، ﺣﺴﺎب ﮐﺎرﺑﺮی و اﻃﻠﺎﻋﺎت ﮐﺎرت ﺑﺎنکی ﺧﻮد را در اﺧﺘﯿﺎر دیگری ﻗﺮار ﻧﺪاده ام و ﻣﺘﻌﻬﺪ ﻣﯽ ﺷﻮم از درﯾﺎﻓﺖ
      وﺟﻪ و ﺧﺮﯾﺪ ارز دﯾﺠﯿﺘﺎل ﺑﺮای اﺷﺨﺎص دیگر ﺗﺤﺖ ﻫﺮ ﻋﻨﻮانی (آﮔﻬﯽ اﺳﺘﺨﺪام، ﮐﺴﺐ ﺳﻮد، ﮐﺎرﯾﺎﺑﯽ در ﻣﻨﺰل و ..) خودداری کنم.در
      ﻏﯿﺮ اﯾﻦ ﺻﻮرت ﭼﻨﺎﻧﭽﻪ وﺟﻬﯽ از سوی دیگران ﺑﺮای ﺧﺮﯾﺪ ﺑﻪ ﺣﺴﺎﺑﻢ وارﯾﺰ ﺷﺪ، ﻣﺘﻮﺟﻪ ﻫﺴﺘﻢ ﮐﻪ ﻣﻤﮑﻦ اﺳﺖ وﺟﻪ وارﯾﺰی ﺳﺮﻗﺘﯽ ﺑﺎﺷﺪ،
      ﻣﺴﻮلیت حقوقی و قضایی آن اﻋﻢ از ﻣﺠﺎزات ﻗﺎﻧﻮﻧﯽ و ﺟﺒﺮان ﺿﺮر و زﯾﺎن شاکی ﺑﺮ ﻋﻬﺪه ﺑﻨﺪه ﺧﻮاﻫﺪ ﺑﻮد.</p>
    <p>

<span class="regular">
     <input type="checkbox" @change="check = !check">
  متن بالا را مطالعه کردم و پذیرفتم.
</span>

    </p>
    <button class="btn-primary" @click.prevent="modal =true" :disabled="!check">تایید و دریافت کد</button>
<Finnotech v-if="modal" @close="modal=false"/>
  </div>

</template>

<script>
import Finnotech from "@/components/Panel/KYC/Finotech";
export default {
  name: "otp",
  components: {Finnotech},
  data(){
    return {
      check: false,
      modal:false
    }
  }
}
</script>

<style lang="scss" scoped>
.otp{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  button{
    width: 100%;
    max-width: 236px;
    align-self: center;
    height: 44px;
  }
}
</style>