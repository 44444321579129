<template>
  <div class="document">
    <p class="regular">اﯾﻦ ﺟﺎﻧﺐ زهراگودرزی ﺑﺎ ﺷﻤﺎره ﻣﻠﯽ 12389123465 در ﺗﺎرﯾﺦ 01/01/11 ﻣﺪارک ﺧﻮد را ﺟﻬﺖ اﺣﺮاز ﻫﻮﯾﺖ در
      ﺳﺎﯾﺖ ویستا ارﺳﺎل و
      اﻋﻠﺎم می کنم ﻣﺪارک، ﺣﺴﺎب ﮐﺎرﺑﺮی و اﻃﻠﺎﻋﺎت ﮐﺎرت ﺑﺎنکی ﺧﻮد را در اﺧﺘﯿﺎر دیگری ﻗﺮار ﻧﺪاده ام و ﻣﺘﻌﻬﺪ ﻣﯽ ﺷﻮم از درﯾﺎﻓﺖ
      وﺟﻪ و ﺧﺮﯾﺪ ارز دﯾﺠﯿﺘﺎل ﺑﺮای اﺷﺨﺎص دیگر ﺗﺤﺖ ﻫﺮ ﻋﻨﻮانی (آﮔﻬﯽ اﺳﺘﺨﺪام، ﮐﺴﺐ ﺳﻮد، ﮐﺎرﯾﺎﺑﯽ در ﻣﻨﺰل و ..) خودداری کنم.در
      ﻏﯿﺮ اﯾﻦ ﺻﻮرت ﭼﻨﺎﻧﭽﻪ وﺟﻬﯽ از سوی دیگران ﺑﺮای ﺧﺮﯾﺪ ﺑﻪ ﺣﺴﺎﺑﻢ وارﯾﺰ ﺷﺪ، ﻣﺘﻮﺟﻪ ﻫﺴﺘﻢ ﮐﻪ ﻣﻤﮑﻦ اﺳﺖ وﺟﻪ وارﯾﺰی ﺳﺮﻗﺘﯽ ﺑﺎﺷﺪ،
      ﻣﺴﻮلیت حقوقی و قضایی آن اﻋﻢ از ﻣﺠﺎزات ﻗﺎﻧﻮﻧﯽ و ﺟﺒﺮان ﺿﺮر و زﯾﺎن شاکی ﺑﺮ ﻋﻬﺪه ﺑﻨﺪه ﺧﻮاﻫﺪ ﺑﻮد.</p>
    <span>
     <input type="checkbox" @change="check = !check">
  متن بالا را مطالعه کردم و پذیرفتم.
</span>

    <div class="">
      <span class="regular otp-confirm">
        <span class="regular">کد تایید متن برای شماره همراه 09456789123 ارسال شد.</span>
        <span class="regular secondary">ارسال مجدد</span>
      </span>
    </div>

    <p class="regular"> لطفا تصویر کارت ملی خود را در کنار چهره‌تان به همراه کد ارسالی به شماره همراهتان به صورت دست
      نوشته، به صورت کاملا خوانا و واضح، مانند تصویر نمونه آپلود نمایید. توجه کنید که متن دست نوشته نبایستی تایپ شود و
      هنگام تهیه عکس، اطلاعات به صورت برعکس و آینه ای ثبت نشوند.</p>

    <div class="doc-box">
      <form class="forms" @submit.prevent="upload" @dragover.prevent @dragenter="hovered=!hovered">
        <label for="picture" class="input-file-btn">
          <div class="input-file-container" @dragleave="hovered=!hovered"
               @drop.stop.prevent="hovered=false;drop($event);" :style="{borderColor : hovered? 'blue' : ''}">
            <img v-if="path" class="img" :src="path" alt="" width="280" height="280">
            <img v-else class="img" src="@/assets/panel/cloud.png">
            <input type="file" name="picture" id="picture" style="display: none" @change="upload($event.target)">

          </div>
          <span class="secondary" v-if="!path"> آپلود تصویر</span>
        </label>
      </form>
      <div class="image-container">
        <span class="cont">
          <span class="regular secondary">نمونه عکس قابل ‌قبول</span>
          <img src="../../../assets/panel/confirmed.png" alt="">
        </span>
        <span class="cont">
          <span class="regular secondary" > نمونه عکس غیرقابل‌قبول</span>
            <img src="../../../assets/panel/declined.png" alt="">
        </span>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "Documents",
  components: {},
  props: ['data'],
  check:false,

  data() {
    return {
      path: '',
      loadedpicture: '',
      hovered: '',
      file:"",
    }
  },
  watch: {
    'data.image'() {
      if (this.data.image)
          // if (typeof this.data.image === 'string')
        this.path = this.data.image
    }
  },
  methods: {
    drop(e) {
      let dt = e.dataTransfer
      this.upload(dt)
    },
    upload(evt) {
      let file = evt.files[0]
      this.file = file
      let fileReader = new FileReader();
      fileReader.addEventListener("loadstart", (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
      })
      fileReader.addEventListener("progress", (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
      });
      fileReader.addEventListener('load', (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
        this.path = event.target.result
      });
      fileReader.readAsDataURL(file)
    },
    hover() {
      this.hovered = true
    }
  }
}
</script>

<style lang="scss" scoped>
.document{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .otp-confirm{
    display: flex;
    gap: 3em;
    flex-wrap: wrap;
  }
}

.doc-box{
  display: flex;
  gap: 3.5em;
  width: 100%;
  flex-wrap: wrap;
}
.forms{
  display: flex;
justify-content: center;
  align-items: center;
  border: 2px dashed #00678F;
  border-radius: 32px;

flex:1;
}
.image-container{
  flex:1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5em;
  .cont{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
}
</style>

