<template>
<div class="modal-backdrop">
  <div class="login-signup">
    <div class="form-container">
      <div class="input-container">
        <div class="header">
          شناسایی دو عاملی
        </div>
        <div class="body">
          <form @submit.prevent="submit">
            <p class="describe">کد ۵ رقمی ارسال شده به شماره خود را وارد نمایید.</p>
            <CodeInput :loading="false" @change="onChange" @complete="submit" :fields="num" ref="code-input"/>
            <div class="footer">
              <a v-if="countDown==0" @click.prevent="send" style="cursor: pointer" class="countdown">ارسال مجدد</a>
              <a v-else style="opacity:0.5;cursor:default" class="countdown">ارسال مجدد</a>
              <a style="text-decoration: none" class="countdown">{{ countDown }} ثانیه </a>
            </div>
<!--            <button type="submit" class="deActive-btn" :class="{'active-btn' : otp.code.length<5}"-->
<!--                    :disabled="otp.code.length<5">ارسال-->
<!--            </button>-->

<div class="buttons">
  <button type="submit" class="btn-green"
          @click="$emit('close')"
  >ارسال
  </button>
</div>


            <p class="goBack" @click="$router.push('account')">
              بازگشت به حساب کاربری
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
// import {
//   reset,
//   paste
// } from "@/Lib/reuseableFunction";

export default {
  name: "Finnotech",
  components: {
    CodeInput
  },
  data() {
    return {
      email: '',
      password: '',
      password2: '',
      type1: 'password',
      type2: 'password',
      checked: '',
      countDown: 120,
      smscode: [],
      error: '',
      loading: false,
      num: 5,
      otp: {
        code: ''
      },
      timeOut: '',
    }
  },
  // methods: {
  //   countDownTimer() {
  //     if (this.countDown > 0) {
  //       this.timeOut = setTimeout(() => {
  //         this.countDown--
  //         this.countDownTimer()
  //       }, 1000)
  //     } else {
  //       this.$error('زمان شما به اتمام رسید', '')
  //     }
  //   },
  //   async send() {
  //     // this.reset()
  //     this.state.loading = true
  //     const res = await this.$axios.get(`/finotech/send-sms-to-customer?email=${this.state.userInfo.email}`)
  //     if (res.message === 'Sms sent to customer.') {
  //       if (this.countDown === 0) {
  //         this.countDown = 120
  //         this.countDownTimer()
  //       }
  //     }
  //   },
  //   onChange(e) {
  //     this.otp.code = e
  //   },
  //   async submit() {
  //     this.state.loading = true
  //     if (this.otp.code.length === 5 || this.loading) {
  //       this.loading = true
  //       let [res,] = await this.$http.get('/finotech/otp', {
  //         params: {
  //           otp: this.otp.code,
  //           email: this.state.userInfo.email
  //         }
  //       })
  //       this.loading = false
  //       if (res) {
  //         await this.$error('', 'فرایند احراز هویت انجام شد. منتظر تایید اطلاعات توسط پشتیبانی باشید.', 'success')
  //         this.$router.push('/profile/account')
  //       }
  //     }
  //     this.state.loading = false
  //   },
  //   paste,
  //   reset,
  // },
  // created() {
  //   this.countDownTimer()
  // },
  // beforeDestroy() {
  //   clearTimeout(this.timeOut)
  // },
  // mounted() {
  //   // this.setPlaceholder()
  // }
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}
.login-signup {
  // background-image: url('../../assets/Home/login.png');
  width: 100%;

  height: calc(100vh - 48px);
  display: flex;
  box-sizing: border-box;
  padding: 56px 10px 10px 10px;
  justify-content: center;
  //background-color: #031B27;
  background-repeat: no-repeat;
  background-size: cover;

  .form-container {

    max-width: 384px;
    min-width: 300px;
    width: 100%;
    max-height: 571px;
    //background: #001E3D;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px 16px 24px;
    height: max-content;

    .title-container {
      height: 107px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.header {
  font-size: 20px;
  //color: #FEFEFE;
  text-align: center;
}

.input-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  //   margin-bottom: 40px;
  padding: 15px ;
  border-radius: 8px;
  justify-content: space-between;
  row-gap: 40px;
}

.describe {
  font-size: 12px;
  //color: #FEFEFE;
}

.deActive-btn,
.active-btn {
  border-radius: 6px;
  border: none;
  //color: #FCFCFC;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  height: 44px;
  color: black;
}

.active-btn {
  background: green;
}

.deActive-btn {
  opacity: 0.5;
  background: green;
}

.footer {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //color: #FEFEFE;
}

.goBack {
  color: #E89900;
  text-decoration: underline;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 0;
  margin-top: 25px;
  cursor: pointer;
}
.btn-green{
  width: 100%;
  max-width: 130px;
  height: 38px;
align-self: center;
}
.buttons{
  display: flex;
  justify-content:center;
}
</style>
